import React from "react";
import { useState, useContext, useEffect } from "react";
import { Modal, ModalBody, Spinner, Input, FormGroup, Button, Badge } from "reactstrap";
import Swal from "sweetalert2";
import { AppContext } from "./AppContext";
import { Panel } from "./Panel";

export const Consulta = (props) => {
  const edoGlobal = useContext(AppContext);
  let [pac, setPaciente] = useState({});
  let [cargando, setCargando] = useState(false);
  let [Fecha, setFecha] = useState(new Date().yyyymmdd("-"));
  let [Peso, setPeso] = useState(0);
  let [mostrarDialogo,setMostrarDialogo] = useState(false)
  let [consultas,setConsultas] = useState([]);

  useEffect(() => {
    (async () => {
      setCargando(true);
      let url = `${edoGlobal.urlws}/paciente/cargar/${props.idPac}`;
      let resp = await fetch(url);
      const pac = await resp.json();
      url = `${edoGlobal.urlws}/paciente/consultas/${props.idPac}`;
      resp = await fetch(url);
      const cons = await resp.json();
      setCargando(false);
      setConsultas(cons);
      setPaciente(pac);
    })();
  }, [props.idPac]);
  async function cargarConsultas(){
    setCargando(true);
    let url = `${edoGlobal.urlws}/paciente/consultas/${props.idPac}`;
    let resp = await fetch(url);
    const cons = await resp.json();
    setCargando(false);
    setConsultas(cons);
  }
  async function guardar(mediciones) {
    let Nutriologo = edoGlobal.usuario.Id;
    const dato = {
      Id: 0,
      Paciente : pac.Id,
      Nutriologo, Fecha,
      ...mediciones
    };
    let url = `${edoGlobal.urlws}/paciente/guardarConsulta`;
    setCargando(true);
    const json = JSON.stringify(dato);
    const resp = await fetch(url, {
      method: "POST", // or 'PUT'
      body: json, // data can be `string` or {object}!
    });
    const res = await resp.text();
    setCargando(false);
    if (res === "ok") {
      Swal.fire("Atencion", "Datos guardados", "success");
      setMostrarDialogo(false);
      cargarConsultas();
    } else 
       Swal.fire("Atencion", res, "error");
  }
  function cancelar() {
    props.cerrar(false);
  }
  const estilo1 = { display: mostrarDialogo ? 'none' : 'inline' };
  const estilo2 = { display: !mostrarDialogo ? 'none' : 'inline' };
  return (
    <Panel titulo={pac.Nombre + " " + pac.ApePat + " " + pac.ApeMat}>
      <div>
        <table>
          <tr>
            <td>Fecha Nac.</td>
            <td>
              <Input size="sm" type="date" value={pac.FechaNac} />
            </td>
            <td>&nbsp;&nbsp;Altura&nbsp;</td>
            <td style={{ width: 60 }}>
              <Input size="sm" value={pac.Altura} />
            </td>
            <td>&nbsp;&nbsp;Peso inicial&nbsp;</td>
            <td style={{ width: 60 }}>
              <Input size="sm" value={pac.PesoInicial} />
            </td>
            <td>&nbsp;&nbsp;Edad&nbsp;</td>
            <td style={{ width: 60 }}>
              <Input size="sm" value={pac.Edad} />
            </td>
            <td>&nbsp;&nbsp;Nivel AF&nbsp;</td>
            <td style={{ width: 100 }}>
              <Input size="sm" value={pac.NivelAF} />
            </td>
            <td>
              <span>&nbsp;&nbsp;&nbsp;</span>
            </td>
            <td>&nbsp;&nbsp;Fecha&nbsp;</td>
            <td>
              <Input type="date" size="sm" value={Fecha} onChange={(e) => setFecha(e.target.value)} />
            </td>
            <td>
              <Button size="sm" outline color="success" onClick={() => setMostrarDialogo(true)}>
                <img src="/imagenes/add.png" alt="nueva consulta" /> Nueva consulta
              </Button>
            </td>
          </tr>
        </table>
        <br />
        <div style={estilo1}>
          <Consultas consultas={consultas} />
          <br />
          <Button onClick={cancelar} size="sm" color="warning">
            Regresar
          </Button>
        </div>
        <div style={estilo2}>
          <CapturaConsulta guardar={guardar} cancelar={() => setMostrarDialogo(false)} />
        </div>
      </div>
      {cargando ? (
        <div className="loader">
          <Spinner animation="border" color="warning" />
        </div>
      ) : null}
    </Panel>
  );
};
function CapturaConsulta(props) {
  const edoGlobal = useContext(AppContext);
  let [Peso, setPeso] = useState(0);
  let [Talla, setTalla] = useState(0);
  let [Cuello, setCuello] = useState(0);
  let [Hombros, setHombros] = useState(0);
  let [BrazoDer, setBrazoDer] = useState(0);
  let [BrazoIzq, setBrazoIzq] = useState(0);
  let [AnteBrazoDer, setAnteDer] = useState(0);
  let [AnteBrazoIzq, setAnteIzq] = useState(0);
  let [Torax, setTorax] = useState(0);
  let [Cintura, setCintura] = useState(0);
  let [Cadera, setCadera] = useState(0);
  let [Abdomen, setAbdomen] = useState(0);
  let [MusloDer, setMusloDer] = useState(0);
  let [MusloIzq, setMusloIzq] = useState(0);
  let [PantDer, setPantDer] = useState(0);
  let [PantIzq, setPantIzq] = useState(0);
  let [Muneca, setMuneca] = useState(0);
  let [PBiceps, setPBiceps] = useState(0);
  let [PTriceps, setPTriceps] = useState(0);
  let [PEspalda, setPEspalda] = useState(0);
  let [PSupra, setPSuprailiaco] = useState(0);
  let [PAbdomen, setPAbdomen] = useState(0);
  let [PMuslo, setPMuslo] = useState(0);
  let [PPanto, setPantorrilla] = useState(0);
  let [Comentarios, setComentarios] = useState("");

  function cancelar(){
    props.cancelar();
  }
  function guardar() {
    const dato = {
      Peso,Talla,Cuello,Hombros,BrazoIzq,BrazoDer,AnteBrazoIzq,AnteBrazoDer,
      Torax,Cintura,Cadera,Abdomen,MusloIzq,MusloDer,PantIzq,PantDer,Muneca,
      PBiceps,PTriceps,PEspalda,PSupra,PAbdomen,PMuslo,PPanto,
      Comentarios,
    };
    props.guardar(dato);
  }


  let SumaGral = Number(PBiceps) + Number(PTriceps) + Number(PEspalda) + Number(PSupra) + Number(PAbdomen) + Number(PMuslo) + Number(PPanto);
  let SumaBasica = Number(PBiceps) + Number(PTriceps) + Number(PEspalda) + Number(PSupra);
  return (
    <>
      <div className="row">
        <div className="col-1">
          <FormGroup>
            <span>Peso</span>
            <Input size="sm" type="text" value={Peso} onChange={(e) => setPeso(e.target.value)} />
          </FormGroup>
        </div>
        <div className="col-1">
          <FormGroup>
            <span>Talla</span>
            <Input size="sm" type="text" value={Talla} onChange={(e) => setTalla(e.target.value)} />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table border="1" style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <td colSpan={15}>
                  <b>Circunferencias corporales</b>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td colSpan={2}>Brazo</td>
                <td colSpan={2}>Antebrazo</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan={2}>Muslo</td>
                <td colSpan={2}>Pantorrila</td>
                <td></td>
              </tr>
              <tr>
                <td>Cuello</td>
                <td>Hombros</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Torax</td>
                <td>Cintura</td>
                <td>Cadera</td>
                <td>Abdomen</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Muñeca</td>
              </tr>
              <tr>
                <td>
                  <Input size="sm" value={Cuello} onChange={(e) => setCuello(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={Hombros} onChange={(e) => setHombros(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={BrazoDer} onChange={(e) => setBrazoDer(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={BrazoIzq} onChange={(e) => setBrazoIzq(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={AnteBrazoDer} onChange={(e) => setAnteDer(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={AnteBrazoIzq} onChange={(e) => setAnteIzq(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={Torax} onChange={(e) => setTorax(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={Cintura} onChange={(e) => setCintura(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={Cadera} onChange={(e) => setCadera(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={Abdomen} onChange={(e) => setAbdomen(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={MusloDer} onChange={(e) => setMusloDer(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={MusloIzq} onChange={(e) => setMusloIzq(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PantDer} onChange={(e) => setPantDer(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PantIzq} onChange={(e) => setPantIzq(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={Muneca} onChange={(e) => setMuneca(e.target.value)} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-5">
          <table border="1" style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <td colSpan={9}>
                  <b>Plieges de Grasa (MM)</b>
                </td>
              </tr>
              <tr>
                <td>Biceps</td>
                <td>Triceps</td>
                <td>Espalda</td>
                <td>Suprailiaco</td>
                <td>Abdomen</td>
                <td>Muslo</td>
                <td>Pantorrilla</td>
                <td>Suma Gral.</td>
                <td>Suma basica</td>
              </tr>
              <tr>
                <td>
                  <Input size="sm" value={PBiceps} onChange={(e) => setPBiceps(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PTriceps} onChange={(e) => setPTriceps(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PEspalda} onChange={(e) => setPEspalda(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PSupra} onChange={(e) => setPSuprailiaco(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PAbdomen} onChange={(e) => setPAbdomen(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PMuslo} onChange={(e) => setPMuslo(e.target.value)} />
                </td>
                <td>
                  <Input size="sm" value={PPanto} onChange={(e) => setPantorrilla(e.target.value)} />
                </td>
                <td>
                  <b>{SumaGral}</b>
                </td>
                <td>
                  <b>{SumaBasica}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
      </div>
      <div className="row">
        <div className="col">
          <span>Comentarios</span>
          <br />
          <Input size="sm" type="textarea" value={Comentarios} onChange={(e) => setComentarios(e.target.value)} />
        </div>
      </div>
      <br />
      <Button onClick={guardar} size="sm" color="success" outline>
        <img src="/imagenes/ok.png" alt="aceptar" />
        Aceptar
      </Button>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <Button onClick={cancelar} size="sm" color="danger" outline>
        <img src="/imagenes/cross.png" alt="cancelar" />
        Cancelar
      </Button>
    </>
  );
}
function Consultas(props) {
  return (
    <>
      <div className="row">
        <div className="col">
          <table border="1" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }} colSpan={18}>
                  <b>Circunferencias corporales</b>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan={2}>Brazo</td>
                <td colSpan={2}>Antebrazo</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan={2}>Muslo</td>
                <td colSpan={2}>Pantorrila</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ width: 70 }}>Fecha</td>
                <td>Peso</td>
                <td>Cuello</td>
                <td>Hombros</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Torax</td>
                <td>Cintura</td>
                <td>Cadera</td>
                <td>Abdomen</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Der.</td>
                <td>Izq.</td>
                <td>Muñeca</td>
                <td>Comentarios</td>
              </tr>
              {props.consultas.map((o, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <span>{o.Fecha}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Peso}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Cuello}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Hombros}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.BrazoDer}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.BrazoIzq}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.AnteBrazoDer}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.AnteBrazoIzq}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Torax}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Cintura}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Cadera}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Abdomen}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.MusloDer}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.MusloIzq}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PantDer}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PantIzq}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.Muneca}</span>
                    </td>
                    <td>
                      <span>{o.Comentarios}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col">
          <table border="1" style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <td colSpan={10}>
                  <b>Plieges de Grasa (MM)</b>
                </td>
              </tr>
              <tr>
                <td>Fecha</td>
                <td>Biceps</td>
                <td>Triceps</td>
                <td>Espalda</td>
                <td>Suprailiaco</td>
                <td>Abdomen</td>
                <td>Muslo</td>
                <td>Pantorrilla</td>
                <td>Suma Gral.</td>
                <td>Suma basica</td>
              </tr>
              {props.consultas.map((o, i) => {
                let sumaGral = o.PBiceps + o.PTriceps + o.PAbdomen;
                let sumaBasica = o.PBiceps + o.PTriceps;
                return (
                  <tr key={i}>
                    <td>
                      <span>{o.Fecha}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PBiceps}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PTriceps}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PEspalda}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PSupra}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PAbdomen}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PMuslo}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{o.PPanto}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{sumaGral}</span>
                    </td>
                    <td className="numeroCeldaMin">
                      <span>{sumaBasica}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="col-7">
          <table border="1" style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <td colSpan={16}>
                  <b>Resultados</b>
                </td>
              </tr>
              <tr>
                <td></td>
                <td colspan="5">Masa grasa</td>
                <td colspan="5">Masa Muscular</td>
                <td></td>
                <td colspan="2">Obj.Perdida</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td colspan="2">Actual</td>
                <td colspan="2">Cambio</td>
                <td></td>
                <td colspan="2">Actual</td>
                <td colspan="2">Cambio</td>
                <td></td>
                <td>ICC</td>
                <td colspan="2">de grasa</td>
                <td>IMC</td>
                <td>DX</td>
              </tr>
              <tr>
                <td>Fecha</td>
                <td className="numeroCeldaMin">%</td>
                <td className="numeroCeldaMin">Kgs</td>
                <td className="numeroCeldaMin">%</td>
                <td className="numeroCeldaMin">Kgs</td>
                <td></td>
                <td className="numeroCeldaMin">%</td>
                <td className="numeroCeldaMin">Kgs</td>
                <td className="numeroCeldaMin">%</td>
                <td className="numeroCeldaMin">Kgs</td>
                <td></td>
                <td className="numeroCeldaMin"></td>
                <td className="numeroCeldaMin">%</td>
                <td className="numeroCeldaMin">Kgs</td>
                <td></td>
                <td></td>
              </tr>
              {props.consultas.map((o, i) => (
                <tr key={i}>
                  <td>{o.Fecha}</td>
                  <td>{o.PtjeMasa}</td>
                  <td>{o.KgMasa}</td>
                  <td>{o.PtjeMasaAnt}</td>
                  <td>{o.KgMasaAnt}</td>
                  <td>
                    {o.PtjeMasaAnt > 0 ? <img src="/imagenes/arriba.png" /> : null}
                    {o.PtjeMasaAnt < 0 ? <img src="/imagenes/abajo.png" /> : null}
                  </td>
                  <td>{o.PtjeMusc}</td>
                  <td>{o.KgMusc}</td>
                  <td>{o.PtjeMuscAnt}</td>
                  <td>{o.KgMuscAnt}</td>
                  <td>
                    {o.PtjeMuscAnt > 0 ? <img src="/imagenes/arriba.png" /> : null}
                    {o.PtjeMuscAnt < 0 ? <img src="/imagenes/abajo.png" /> : null}
                  </td>
                  <td>{o.Icc}</td>
                  <td></td>
                  <td></td>
                  <td>{o.Imc}</td>
                  <td>{o.Dx}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
      </div>
    </>
  );
}
