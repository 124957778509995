import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Paciente = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [Nombre,setNombre] = useState("");
    let [ApePat,setApePat] = useState("");
    let [ApeMat,setApeMat] = useState("");
    let [Sexo,setSexo] = useState("");
    let [FechaNac,setFechaNac] = useState("");
    let [Edad,setEdad] = useState(0);
    let [Altura,setAltura] = useState(0);
    let [PesoInicial,setPesoIni] = useState(0);
    let [Ocupacion,setOcupacion] = useState("");
    let [Escolaridad,setEscolaridad] = useState("");
    let [EdoCivil,setEdoCivil] = useState("");
    let [Deporte,setDeporte] = useState("");
    let [DiasDeportes,setDias] = useState(0);
    let [Fuma,setFuma] = useState(false);
    let [Toma,setToma] = useState(false);
    let [Deportes,setPracticaDep] = useState(false);
    let [Sal,setSal] = useState(false);
    let [Agua,setAgua] = useState(0);
    let [HorasSuenio,setSuenio] = useState(0);
    let [Comidas,setComidas] = useState(0);
    let [Complementos,setComplementos] = useState(false);
    let [Enfermedades,setEnfermedades] = useState("");
    let [ObsBucal,setObsBucal] = useState("");
    let [ObsMusculo,setObsMusc] = useState("");
    let [Analisis,setAnalisis] = useState("");
    let [Alergias,setAlergias] = useState("");
    let [Email,setEmail] = useState("");
    let [Telefono,setTelefono] = useState("");
    let [cargando,setCargando] = useState(false);
    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/paciente/cargar/${props.idPac}`;
            let resp = await fetch(url);
            const pac = await resp.json();
            setCargando(false);
            setNombre(pac.Nombre);
            setApePat(pac.ApePat);
            setApeMat(pac.ApeMat);
            setSexo(pac.Sexo);
            setFechaNac(pac.FechaNac);
            setEdad(pac.Edad);
            setAltura(pac.Altura);
            setPesoIni(pac.PesoInicial);
            setOcupacion(pac.Ocupacion);
            setEscolaridad(pac.Escolaridad);
            setEdoCivil(pac.EdoCivil);
            setDeporte(pac.Deporte);
            setDias(pac.DiasDeportes);
            setFuma(pac.Fuma);
            setToma(pac.Toma);
            setPracticaDep(pac.Deportes);
            setSal(pac.Sal);
            setAgua(pac.Agua);
            setSuenio(pac.HorasSuenio);
            setComidas(pac.Comidas);
            setComplementos(pac.Complementos);
            setEnfermedades(pac.Enfermedades);
            setObsBucal(pac.ObsBucal);
            setObsMusc(pac.ObsMusculo);
            setAnalisis(pac.Analisis);
            setAlergias(pac.Alergias);
            setTelefono(pac.Telefono);
            setEmail(pac.Email);
        })();
    }, [props.idPac]);

    async function calcularEdad(){
        let url = `${edoGlobal.urlws}/paciente/calcularEdad/${FechaNac}`;
        let resp = await fetch(url);
        let edad = await resp.text();
        setEdad(Number(edad));
    }
    async function guardar(){
        let Nutriologo = edoGlobal.usuario.Id;
        let Activo = true;
        let FechaReg = "";
        let Pwd = "";
        const dato = {
            Id : props.idPac, 
            Nutriologo, Activo,Pwd,
            ApePat,ApeMat ,Nombre ,Sexo ,FechaNac ,Email ,Telefono  ,FechaReg ,
            Sal,Altura ,PesoInicial ,Fuma,Toma,Ocupacion,Escolaridad ,EdoCivil,
            Deportes,Deporte,DiasDeportes,Agua, Complementos,Enfermedades,ObsBucal,ObsMusculo,
            Analisis,Alergias,HorasSuenio,Comidas 
        }
        let url = `${edoGlobal.urlws}/paciente/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            props.cerrar(true);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        props.cerrar(false);
    }
    return(
        <Panel titulo="Paciente">
            <div >
                <div className='row'>
                    <div className='col-7'>
                        <div>
                            <div className="row">
                                <div className="col">
                                    <FormGroup  >
                                        <span>Apellido Paterno</span>
                                        <Input size="sm" type="text" value={ApePat} onChange={e => setApePat(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Apellido Materno</span>
                                        <Input size="sm" type="text" value={ApeMat} onChange={e => setApeMat(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Nombre</span>
                                        <Input size="sm" type="text" value={Nombre} onChange={e => setNombre(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Fuma</span><br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={Fuma} onChange={e => setFuma(e.target.checked)} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <FormGroup  >
                                        <span>Sexo</span>
                                        <Input type="select" size="sm" value={Sexo} onChange={e => setSexo(e.target.value)}>
                                            <option value="">Especifique</option>
                                            <option value="M">Mujer</option>
                                            <option value="H">Hombre</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <FormGroup  >
                                        <span>Fecha Nac.</span>
                                        <Input size="sm" type="date" value={FechaNac} onChange={e => setFechaNac(e.target.value)} onBlur={()=>calcularEdad()} />
                                    </FormGroup>
                                </div>
                                <div className="col-1">
                                    <FormGroup  >
                                        <span>Edad</span>
                                        <Input size="sm" value={Edad} />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Altura</span>
                                        <Input size="sm" type="text" value={Altura} onChange={e => setAltura(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Peso inicial</span>
                                        <Input size="sm" type="text" value={PesoInicial} onChange={e => setPesoIni(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Toma</span><br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={Toma} onChange={e => setToma(e.target.checked)} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Ocupacion</span>
                                        <Input size="sm" type="text" value={Ocupacion} onChange={e => setOcupacion(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Escolaridad</span>
                                        <Input type="select" size="sm" value={Escolaridad} onChange={e => setEscolaridad(e.target.value)}>
                                            <option value="">Especifique</option>
                                            <option value="P">Primaria</option>
                                            <option value="S">Secundaria</option>
                                            <option value="B">Preparatoria</option>
                                            <option value="U">Universidad</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup  >
                                        <span>Edo.Civil</span>
                                        <Input type="select" size="sm" value={EdoCivil} onChange={e => setEdoCivil(e.target.value)}>
                                            <option value="">Especifique</option>
                                            <option value="S">Soltero</option>
                                            <option value="C">Casado</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Deporte</span><br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={Deportes} onChange={e => setPracticaDep(e.target.checked)} />
                                    </FormGroup>
                                </div>
                                <div className="col-9">
                                    <FormGroup  >
                                        <span>Cual</span>
                                        <Input size="sm" type="text" value={Deporte} onChange={e => setDeporte(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-1">
                                    <FormGroup  >
                                        <span>Dias</span>
                                        <Input size="sm" type="text" value={DiasDeportes} onChange={e => setDias(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Cuanta agua tomas</span>
                                        <Input size="sm" type="text" value={Agua} onChange={e => setAgua(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Añade sal a la comida</span><br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={Sal} onChange={e => setSal(e.target.checked)} />
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Cuantas horas de sueño</span>
                                        <Input size="sm" type="text" value={HorasSuenio} onChange={e => setSuenio(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Cuantas comidas al dia</span>
                                        <Input size="sm" type="text" value={Comidas} onChange={e => setComidas(e.target.value)} />
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <FormGroup  >
                                        <span>Ha usado EAA o complementos</span><br />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={Complementos} onChange={e => setComplementos(e.target.checked)} />
                                    </FormGroup>
                                </div>
                            </div>
                            <br/><br/><br/>
                            <Button onClick={guardar} size="sm" outline color="success">Guardar</Button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <Button onClick={cancelar} size="sm" outline color="danger">Cancelar</Button>
                        </div>
                    </div>
                    <div className='col-5'>
                        <div className='row'>
                            <div className="col">
                                <FormGroup  >
                                    <span>Enfermedades personales (hereditarias y/o transtornos</span>
                                    <Input size="sm" type="textarea" value={Enfermedades} onChange={e => setEnfermedades(e.target.value)} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <FormGroup  >
                                    <span>Observacion bucal</span>
                                    <Input size="sm" type="textarea" value={ObsBucal} onChange={e => setObsBucal(e.target.value)} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <FormGroup  >
                                    <span>Observacion musculo esqueletico</span>
                                    <Input size="sm" type="textarea" value={ObsMusculo} onChange={e => setObsMusc(e.target.value)} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <FormGroup  >
                                    <span>Analisis clinicos</span>
                                    <Input size="sm" type="textarea" value={Analisis} onChange={e => setAnalisis(e.target.value)} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <FormGroup  >
                                    <span>Alergias</span>
                                    <Input size="sm" type="textarea" value={Alergias} onChange={e => setAlergias(e.target.value)} />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
        </Panel> 
    )
}