import React from 'react';
import {useState, useContext, useEffect} from 'react';
import Calendar from 'react-calendar/dist/umd/Calendar';
import { Button, Spinner, Row, Table, Modal, ModalHeader, ModalBody, Input, FormGroup } from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tabla } from './Tabla';

export const Citas = () => {
    const edoGlobal = useContext(AppContext);
    let [fecha,setFecha] = useState(new Date());
    let [citas,setCitas] = useState([]);
    let [idCita,setIdCita] = useState(0);
    let [pacientes,setPacientes] = useState([]);
    let [cargando,setCargando] = useState(false);
    let [selPac,setSelPac] = useState(false);
    let [hora,setHora] = useState("");
    let [nombre,setNombre] = useState("");
    let [apePat,setApePat] = useState("G");
    let [apeMat,setApeMat] = useState("");

    useEffect(() => {
        (async () => {
            cargarCitas();
        })();
    }, [fecha]);

    async function cargarCitas(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/cita/citas/${edoGlobal.usuario.Id}?fecha=${fecha.yyyymmdd('-')}`;
        let resp = await fetch(url);
        const citas = await resp.json();
        setCitas(citas);
        setCargando(false);
    }
    async function cargarPacientes(){
        if (nombre + apePat + apeMat === "")
            return;
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/lista?nutriologo=${edoGlobal.usuario.Id}&apePat=${apePat}&apeMat=${apeMat}&nombre=${nombre}`;
        let resp = await fetch(url);
        const pacientes = await resp.json();
        setCargando(false);
        setPacientes(pacientes);
    }
    function seleccionar(i){
        const nom = pacientes[i].N + ' ' + pacientes[i].P + ' ' + pacientes[i].M;
        const MySwal = withReactContent(Swal)
        setSelPac(false);
        MySwal.fire({
            title: 'Confirme',
            text: "¿ agendar a " + nom + " a las " + hora + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                grabaCita(i);
            }
          })        
    }
    async function grabaCita(i){
        let Nutriologo = edoGlobal.usuario.Id;
        let Activo = true;
        let FechaReg = "";
        let Pwd = "";
        const dato = {
            Id : idCita, 
            Nutriologo,
            Paciente : pacientes[i].I,
            Fecha : fecha.yyyymmdd('-'),
            Hora:hora
        }
        let url = `${edoGlobal.urlws}/cita/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            cargarCitas()
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function editar(i){
        setIdCita(citas[i].I);
        setHora(citas[i].H);
        setSelPac(true);
    }
    function eliminar(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ quitar la cita de " + citas[i].N + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                eliminarB(i);
            }
          })        
    }
    async function eliminarB(i){
        let url = `${edoGlobal.urlws}/cita/eliminar/${citas[i].I}`;
        const resp = await fetch(url,{ method: 'POST'});
        const res = await resp.text();
        if(res === "ok"){
            cargarCitas();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    return(
        <div>
            <Row>
                <div className='col-3'>
                    <Calendar onChange={setFecha} value={fecha}/>
                </div>
                <div className='col'>
                    <h4>{fecha.yyyymmdd('-')}   <Button outline size="sm" color="success"><img src="/imagenes/ok.png" alt="nueva cita" /></Button></h4>
                    <Table size="sm" striped hover>
                        <thead>
                            <tr>
                                <th className='numeroCeldaCh'>Hora</th>
                                <th>Paciente</th>
                                <th style={{width:'30px'}}></th>
                                <th style={{width:'30px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {citas.map((o,i)=>
                                <tr key={i}>
                                    <td>{o.H}</td>
                                    <td>{o.N}</td>
                                    <td><img src="/imagenes/edit.png" alt="editar" onClick={()=> editar(i)} /></td>
                                    <td>
                                        {o.I === 0 ? null : <img onClick={()=> eliminar(i)} src="/imagenes/menos.png" alt="eliminar" />}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table> 
                </div>
            </Row>
            <Modal size="lg" isOpen={selPac} toggle={() => setSelPac(false)}>
                <ModalHeader>
                    <span>Indique el paciente para cita a las {hora}</span>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="row">
                            <div className="col-2">
                                <FormGroup  >
                                    <span>Apellido Paterno</span>
                                    <Input size="sm" type="text" value={apePat} onChange={e => setApePat(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup  >
                                    <span>Apellido Materno</span>
                                    <Input size="sm" type="text" value={apeMat} onChange={e => setApeMat(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup  >
                                    <span>Nombre</span>
                                    <Input size="sm" type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div className="col-1">
                                <br />
                                <Button outline color="success" size="sm" onClick={cargarPacientes}><img src="imagenes/zoom.png" alt="buscar" /></Button>
                            </div>
                        </div>
                        <Tabla campos={["P", "M", "N"]}
                            cols={["Apellido Paterno", "Apellido Materno", "Nombre"]}
                            tams={[150, 150, 150, 30, 30]}
                            datos={pacientes}
                            opciones={[{ tit: 'seleccionar', img: 'ok', fn: seleccionar }]}
                        />
                    </div>
                </ModalBody>
            </Modal>
            {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
        </div>
    );
}