import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';
import { DietaDet } from './DietaDet';

export const Dieta = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [Tiempos,setTiempos] = useState(3);
    let [KCal,setKCal] = useState(0);
    let [PtjeGrasa,setPtjeGrasa] = useState(0);
    let [PtjeProts,setPtjeProts] = useState(0);
    let [PtjeChos,setPtjeChos] = useState(0);
    let [Indicaciones,setIndicaciones] = useState("");
    let [Descripcion,setDescripcion] = useState("");
    let [cargando,setCargando] = useState(false);
    let [enDetalle,setEnDetalle] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/dieta/cargar/${props.idDieta}`;
            let resp = await fetch(url);
            const a = await resp.json();
            setTiempos(a.Tiempos);
            setKCal(a.KCal);
            setPtjeGrasa(a.PtjeGrasa);
            setPtjeProts(a.PtjeProts);
            setPtjeChos(a.PtjeChos);
            setIndicaciones(a.Indicaciones);
            setDescripcion(a.Descripcion);
            setCargando(false);
        })();
    }, [props.idDieta]);

    async function guardar(){
        let Nutriologo = edoGlobal.usuario.Id;
        let FechaReg = "";
        const dato = {
            Id : props.idDieta, 
            Nutriologo,Paciente: props.Paciente,
            Tiempos, KCal, PtjeGrasa, PtjeProts, PtjeChos, Descripcion, Indicaciones
        }
        let url = `${edoGlobal.urlws}/dieta/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            props.cerrar(true);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        props.cerrar(false);
    }
    function regresar(){
      setEnDetalle(false);
    }
    const estilo1 = { display: enDetalle ? 'none' : 'inline' };
    const estilo2 = { display: enDetalle ? 'inline' : 'none' };

    return (
      <Panel titulo={Descripcion}>
        <div style={estilo2}>
          <DietaDet idDieta={props.idDieta} regresar={regresar}/>
        </div>
        <div style={estilo1}>
          <div className="row">
            <div className="col-10">
              <FormGroup>
                <span>Descripcion</span>
                <Input size="sm" type="text" value={Descripcion} onChange={(e) => setDescripcion(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <span>Tiempos</span>
                <Input size="sm" type="text" value={Tiempos} onChange={(e) => setTiempos(e.target.value)} />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <FormGroup>
                <span>KCalorias</span>
                <Input size="sm" type="text" value={KCal} onChange={(e) => setKCal(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <span>% Grasas </span>
                <Input size="sm" type="text" value={PtjeGrasa} onChange={(e) => setPtjeGrasa(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <span>% Proteinas </span>
                <Input size="sm" type="text" value={PtjeProts} onChange={(e) => setPtjeProts(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <span>% Carbohidratos </span>
                <Input size="sm" type="text" value={PtjeChos} onChange={(e) => setPtjeChos(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-2">
              <br />
              <Button onClick={() => setEnDetalle(true)} size="sm" outline color="secondary">
                <img src="/imagenes/tiempos.png" />
                Tiempos
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>Indicaciones</span>
              <br />
              <Input rows="10" size="sm" type="textarea" value={Indicaciones} onChange={(e) => setIndicaciones(e.target.value)} />
            </div>
          </div>
          <br />
          <br />
          <br />
          <Button onClick={guardar} size="sm" outline color="success">
            Guardar
          </Button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Button onClick={cancelar} size="sm" outline color="danger">
            Cancelar
          </Button>
          {cargando ? (
            <div className="loader">
              <Spinner animation="border" color="warning" />
            </div>
          ) : null}
        </div>
      </Panel>
    );
}