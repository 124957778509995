import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Card, CardHeader, CardBody,InputGroup,InputGroupText, Input, FormGroup, Button, Col, Row, Modal, Container, ModalBody, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';

export const DietaDet = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [tiempos,setTiempos] = useState([]);
    let [alimentos,setAlimentos] = useState([]);
    let [alimento,setAlimento] = useState(0);
    let [cantidad,setCantidad] = useState(0);
    let [renglon,setRenglon] = useState(0);
    let [cargando,setCargando] = useState(false);
    let [capturando,setCapturando] = useState(false);
    const [alimentoCallBack, setAlimentoCallBack] = useState(() => () => console.log("default ooops"));

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/dieta/detalle/${props.idDieta}`;
            let resp = await fetch(url);
            const tiempos = await resp.json();
            setTiempos(tiempos);
            setCargando(false);
        })();
    }, [props.idDieta]);
    async function cargarAlimentos(tipo){
        setCargando(true);
        let url = `${edoGlobal.urlws}/alimento/lista/${edoGlobal.usuario.Id}?tipo=${tipo}`;
        let resp = await fetch(url);
        const a = await resp.json();
        setAlimentos(a);
        setCargando(false);
    }
    function capturar(r,acb){
        setCapturando(true);
        setRenglon(r);
        setAlimentoCallBack((r,a,c,n,p) => (r,a,c,n,p) => acb(r,a,c,n,p));
    }
    function agregarAlimento(){
        try {
            alimentoCallBack(renglon,alimentos[alimento].I,cantidad,alimentos[alimento].N,alimentos[alimento].X);
            setCapturando(false);
        } catch (error) {
            console.error(error);
        }
    }
    return (
      <div>
          <Button onClick={props.regresar} size="sm" color="warning" outline>Regresar</Button>
          <br/>
        {tiempos.map((o, i) => (
          <Tiempo key={i} tiempo={o} capturar={capturar} urlws={edoGlobal.urlws} idDieta={props.idDieta} />
        ))}
          <Button onClick={props.regresar} size="sm" color="warning" outline>Regresar</Button>
        <Modal isOpen={capturando} toggle={() => setCapturando(false)}>
          <ModalHeader>
              <span>Especifique alimento y cantidad</span>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col>
                  <Button size="sm" color="secondary" outline onClick={() => cargarAlimentos("P")}>
                    Proteinas
                  </Button>
                  <span>&nbsp;&nbsp;</span>
                  <Button size="sm" color="secondary" outline onClick={() => cargarAlimentos("G")}>
                    Grasas
                  </Button>
                  <span>&nbsp;&nbsp;</span>
                  <Button size="sm" color="secondary" outline onClick={() => cargarAlimentos("C")}>
                    Carbohidratos
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="col-8">
                  <FormGroup>
                    <span>Alimento</span>
                    <Input type="select" size="sm" value={alimento} onChange={(e) => setAlimento(e.target.value)}>
                      {alimentos.map((i, k) => (
                        <option key={i.I} value={k}>
                          {i.N}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
              </Row>
              <Row>
                <div className="col-3">
                  <FormGroup>
                    <span>Cantidad</span>
                    <Input size="sm" value={cantidad} onChange={(e) => setCantidad(e.target.value)} />
                  </FormGroup>
                </div>
              </Row>
              <Row>
                <div className="col">
                  <Button onClick={agregarAlimento} size="sm" color="success" outline>
                    Aceptar
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Button onClick={() => setCapturando(false)} size="sm" color="danger" outline>
                    Cancelar
                  </Button>
                </div>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    );
}
function Tiempo(props){
    const [renglones,setRenglones] = useState([]);
    const [maxRen,setMaxRen] = useState(false);
    const [Ptje,setPtje] = useState(0);
    const [KCal,setKCal] = useState(0);
    const [x,setX] = useState(0);
    useEffect(() => {
        (async () => {
            let r = 1;
            let rens = props.tiempo.Renglones;
            if (rens.length > 0) 
                r = rens[rens.length - 1].Renglon + 1;
            setRenglones(rens);
            setPtje(props.tiempo.Ptje)
            setKCal(props.tiempo.KCal)
            setMaxRen(r);
        })();
    }, [props.tiempo.Renglones]);
    function agregarRenglon(){
        renglones.push({ Renglon: maxRen, Opciones:[] });
        setMaxRen(maxRen + 1);
    }
    function agregarAlimento(r,a,c,n,m){
        //alert("tiempo : " + props.tiempo.Tiempo + " Renglon: " + r +  " // alimento: " + n + " // cant: " + c);
        guardarDetalle(r,0,a,c,n,m);
        //renglones[r].Opciones.push({A:a,N:n,C:c, M:m});
        //setX(x+1);
    }
    function capturarAlimento(r){
        props.capturar(r,agregarAlimento);
    }
    async function guardarTiempo(){
        let dato = {
            Dieta : props.idDieta, Tiempo : props.tiempo.Tiempo,
            KCal, Ptje
        }
        let url = `${props.urlws}/dieta/guardarTiempo`;
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        if(res !== "ok")
            Swal.fire('Atencion',res,'error');
    }
    async function guardarDetalle(r, o, a, c, n, m){
      let dato = {
          Id : 0,
          Dieta : props.idDieta, Tiempo : props.tiempo.Tiempo,
          Renglon : renglones[r].Renglon, Opcion : o, Alimento : a, Cantidad : c,
          KCal, Ptje
      }
      let url = `${props.urlws}/dieta/guardarDet`;
      const json = JSON.stringify(dato);
      const resp = await fetch(url,{
          method: 'POST', // or 'PUT'
          body: json // data can be `string` or {object}!
        });
      const res = await resp.text();
      if(res === "ok"){
          renglones[r].Opciones.push({A:a,N:n,C:c,M:m});
          setX(x+1);
      }
      else
          Swal.fire('Atencion',res,'error');
  }
  function quitar(r,o,i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea quitar " + o.N + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                quitarB(r,o,i);
            }
          })        
    }
    async function quitarB(r,o,i){
        let url = `${props.urlws}/dieta/quitarDet/${o.I}`;
        const resp = await fetch(url);
        const res = await resp.text();
        if(res === "ok"){
            renglones[r].Opciones.splice(i, 1);
            setX(x+1);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    return (
      <div>
        <Card color="primary" outline style={{ backgroundColor: "transparent", padding: 1 }}>
          <CardHeader>
            <Row>
              <div className='col-2'>
                <b> {props.tiempo.NomTiempo} tiempo</b>
              </div>
              <div className='col-1'>
                <Button onClick={agregarRenglon} size="sm" color="success" outline>
                  <img src="/imagenes/add.png" alt="agregar" />
                </Button>
              </div>
              <div className='col-2'>
                <span>&nbsp;</span>
              </div>
              <div className='col-2'>
                <InputGroup>
                  <InputGroupText size="sm">Kcal</InputGroupText>
                  <Input size="sm" value={KCal} onChange={(e)=> setKCal(e.target.value)} />
                  <Button size="sm" outline color="success" onClick={guardarTiempo}><img src="/imagenes/ok.png" alt="ok"  /></Button>
                </InputGroup>
              </div>
              <div className='col-2'>
                <InputGroup>
                <InputGroupText size="sm">%</InputGroupText>
                  <Input size="sm" value={Ptje} onChange={(e)=> setPtje(e.target.value)} />
                  <Button size="sm" outline color="success" onClick={guardarTiempo}><img src="/imagenes/ok.png" alt="ok"  /></Button>
                </InputGroup>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <ul>
              {renglones.map((o, r) => (
                <li key={r}>
                  <span>Elegir SOLO UNO de los siguientes:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Button onClick={()=>capturarAlimento(r)} size="sm" color="success" outline>
                    <img src="/imagenes/add.png" alt="agregar" />
                  </Button>
                  <ul>
                    {o.Opciones.map((o, i) => (
                      <li className='liMenu' key={i}>
                          <div className='row'>
                              <div className='col'><span>* {o.C} {o.M} de {o.N}</span></div>
                              <div className='col'><img onClick={()=>quitar(r,o,i)} className='imgLink' src="/imagenes/menos.png" alt="quitar" /></div>
                          </div>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
        <br />
      </div>
    );
}