import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Col,Spinner, Input,FormGroup, Button } from 'reactstrap';
import { Panel } from './Panel';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'

export const Perfil = (props) =>{
    const edoGlobal = useContext(AppContext);
    const [Id,setId] = useState(0);
    const [Nombre,setNombre] = useState("");
    const [Email,setEmail] = useState("");
    const [Titulo,setTitulo] = useState("");
    const [Cedula,setCedula] = useState("");
    const [Direccion,setDireccion] = useState("");
    const [Ciudad,setCiudad] = useState("");
    const [Telefono,setTelefono] = useState("");
    const [cargando,setCargando] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/nutriologo/cargar/${edoGlobal.usuario.Id}`;
            let resp = await fetch(url);
            const nut = await resp.json();
            setCargando(false);
            setId(nut.Id);
            setNombre(nut.Nombre);
            setEmail(nut.Email);
            setTitulo(nut.Titulo);
            setCedula(nut.Cedula);
            setDireccion(nut.Direccion);
            setCiudad(nut.Ciudad);
            setTelefono(nut.Telefono);
        })();
    }, []);

    async function guardar(){
        const dato = {
            Id, Nombre,Email,Titulo,Cedula,
            Direccion,Ciudad,Telefono
        }
        let url = `${edoGlobal.urlws}/nutriologo/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    return (
      <Panel titulo="Perfil de nutriologo">
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
        <Row>
            <div className="col-6">
              <FormGroup>
                <span>Nombre</span>
                <Input size="sm" type="text" value={Nombre} onChange={(e) => setNombre(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-4">
              <FormGroup>
                <span>Titulo</span>
                <Input size="sm" type="text" value={Titulo} onChange={(e) => setTitulo(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <span>Cedula</span>
                <Input size="sm" type="text" value={Cedula} onChange={(e) => setCedula(e.target.value)}
                />
              </FormGroup>
            </div>
        </Row>
        <Row>
            <div className="col-4">
              <FormGroup>
                <span>Direccion</span>
                <Input size="sm" type="text" value={Direccion} onChange={(e) => setDireccion(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroup>
                <span>Ciudad</span>
                <Input size="sm" type="text" value={Ciudad} onChange={(e) => setCiudad(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <span>Telefono</span>
                <Input size="sm" type="text" value={Telefono} onChange={(e) => setTelefono(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroup>
                <span>Email</span>
                <Input size="sm" type="text" value={Email} onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </div>
        </Row>
        <br/>
       <Button onClick={guardar} size="sm" outline color="success">Guardar</Button> 
      </Panel>
    );
}