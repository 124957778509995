import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Alimento = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [Nombre,setNombre] = useState("");
    let [Medida,setMedida] = useState("");
    let [Porcion,setPorcion] = useState(0);
    let [Proteinas,setProteinas] = useState(0);
    let [Carbos,setCarbos] = useState(0);
    let [Grasas,setGrasas] = useState("");
    let [Tipo,setTipo] = useState("");
    let [cargando,setCargando] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/alimento/cargar/${props.idAlimento}`;
            let resp = await fetch(url);
            const a = await resp.json();
            setCargando(false);
            setNombre(a.Nombre);
            setMedida(a.Medida);
            setPorcion(a.Porcion);
            setProteinas(a.Proteinas);
            setCarbos(a.Carbos);
            setGrasas(a.Grasas);        
            setTipo(a.Tipo);
        })();
    }, [props.idAlimento]);

    async function guardar(){
        let Nutriologo = edoGlobal.usuario.Id;
        let FechaReg = "";
        const dato = {
            Id : props.idAlimento, 
            Nutriologo,FechaReg,
            Nombre, Medida,Porcion,Proteinas,Carbos,Grasas, Tipo
        }
        let url = `${edoGlobal.urlws}/alimento/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            props.cerrar(true);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        props.cerrar(false);
    }
    return (
      <Panel titulo="Alimento">
        <div className="row">
          <div className="col-9">
            <FormGroup>
              <span>Nombre</span>
              <Input size="sm" type="text" value={Nombre} onChange={(e) => setNombre(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-3">
              <FormGroup>
                <span>Tipo</span>
                <Input type="select" size="sm" value={Tipo} onChange={(e) => setTipo(e.target.value)} >
                  <option value={""}>Especifique</option>
                  {props.tipos.map((i, k) => (
                    <option key={i.I} value={i.I}>
                      {i.N}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
        </div>
        <div className="row">
          <div className="col-3">
            <FormGroup>
              <span>Medida</span>
              <Input size="sm" type="text" value={Medida} onChange={(e) => setMedida(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>Porcion (grs)</span>
              <Input size="sm" type="text" value={Porcion} onChange={(e) => setPorcion(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>Proteinas (grs)</span>
              <Input size="sm" type="text" value={Proteinas} onChange={(e) => setProteinas(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>Chos. (grs)</span>
              <Input size="sm" type="text" value={Carbos} onChange={(e) => setCarbos(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>Grasas (grs)</span>
              <Input size="sm" type="text" value={Grasas} onChange={(e) => setGrasas(e.target.value)} />
            </FormGroup>
          </div>
        </div>
        <br/><br/><br/>
        <Button onClick={guardar} size="sm" outline color="success">Guardar</Button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button onClick={cancelar} size="sm" outline color="danger">Cancelar</Button>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
      </Panel>
    );
}