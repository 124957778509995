import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Table, Input,Spinner, Button } from 'reactstrap';
import { Panel } from './Panel';
import { Tabla } from './Tabla';
import { Dieta } from './Dieta';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'

export const Dietas = (props) =>{
    const edoGlobal = useContext(AppContext);
    const [desde,setDesde] = useState(0);
    const [hasta,setHasta] = useState(3000);
    const [tiempos,setTiempos] = useState(0);
    const [paciente,setPaciente] = useState(0);
    let [dietas,setDietas] = useState([]);
    let [idDieta,setIdDieta] = useState(0);
    let [nombre,setNombre] = useState("");
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);

    async function cargarLista(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/dieta/listaFiltro?nutriologo=${edoGlobal.usuario.Id}&paciente=${paciente}&desde=${desde}&hasta=${hasta}&tiempos=${tiempos}`;
        let resp = await fetch(url);
        const dietas = await resp.json();
        setCargando(false);
        setDietas(dietas);
    }
    function nueva(){
        setIdDieta(0);
        setEditando(true);
    } 
    const editar = i =>{
        setIdDieta(dietas[i].Id);
        setEditando(true);
    }
    const eliminar = i =>{
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarLista();
    }
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return (
      <div>
        <div style={estilo2}>
          <Dieta idDieta={idDieta} paciente={paciente} cerrar={cerrarFormulario} />
        </div>
        <div style={estilo1}>
        <br/>
          <table>
            <tr>
              <td>Desde</td>
              <td>
                <Input style={{ width: 60 }} size="sm" type="text" value={desde} onChange={(e) => setDesde(e.target.value)} />
              </td>
              <td>KCals. &nbsp;&nbsp;&nbsp; Hasta</td>
              <td>
                <Input style={{ width: 60 }} size="sm" type="text" value={hasta} onChange={(e) => setHasta(e.target.value)} />
              </td>
              <td>KCals. &nbsp;&nbsp;&nbsp; Tiempos</td>
              <td>
                <Input style={{ width: 140 }} type="select" size="sm" value={tiempos} onChange={(e) => setTiempos(e.target.value)}>
                  <option value={0}>Todos</option>
                  <option value={2}>2 tiempos</option>
                  <option value={3}>3 tiempos</option>
                  <option value={4}>4 tiempos</option>
                  <option value={5}>5 tiempos</option>
                </Input>
              </td>
              <td>
                &nbsp;&nbsp;&nbsp;
                <Button outline color="success" size="sm" onClick={cargarLista}>
                  <img src="imagenes/ok.png" alt="cargar" />
                </Button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button outline color="success" size="sm" onClick={nueva}>
                  <img src="imagenes/add.png" alt="nueva" />
                </Button>
              </td>
            </tr>
          </table>
          <br/>
          <Tabla
            campos={["Fecha","Desc","KCal","Grasa","Prots","Chos","Tiempos"]}
            cols={["Fecha","Descripcion","KCals.","% Grasas","% Proteinas","%Carbohidratos","Tiempos"]}
            tams={[100,200,70,70,70,70,70,30,30]}
            datos={dietas}
            opciones={[
              { tit: "editar", img: "edit", fn: editar },
              { tit: "eliminar", img: "trash", fn: eliminar },
            ]}
          />

        </div>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
     </div>
    );
}