import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import {Paciente} from './Paciente';
import {Consulta} from './Consulta';

export const Pacientes = () =>{
    const edoGlobal = useContext(AppContext);
    let [pacientes,setPacientes] = useState([]);
    let [idPac,setIdPac] = useState(0);
    let [nombre,setNombre] = useState("");
    let [apePat,setApePat] = useState("G");
    let [apeMat,setApeMat] = useState("");
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    const [consultando,setConsultando] = useState(false);
    
    async function cargarPacientes(){
        if (nombre + apePat + apeMat === "")
            return;
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/lista?nutriologo=${edoGlobal.usuario.Id}&apePat=${apePat}&apeMat=${apeMat}&nombre=${nombre}`;
        let resp = await fetch(url);
        const pacientes = await resp.json();
        setCargando(false);
        setPacientes(pacientes);
    }
    function nuevoPaciente(){
        setIdPac(0);
        setEditando(true);
    } 
    const editar = i =>{
        setIdPac(pacientes[i].I);
        setEditando(true);
    }
    const consultar = i =>{
        setIdPac(pacientes[i].I);
        setConsultando(true);
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarPacientes();
    }
    const estilo1 = { display: editando || consultando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    const estilo3 = { display: consultando ? 'inline' : 'none' };
    return(
        <div>
            <div style={estilo2}><Paciente idPac={idPac} cerrar={cerrarFormulario} /></div>
            <div style={estilo3}><Consulta idPac={idPac} cerrar={()=>setConsultando(false)} /></div>
            <Container style={estilo1}>
                <div className="row">
                    <div className="col-2">
                        <FormGroup  >
                            <span>Apellido Paterno</span>
                            <Input size="sm" type="text" value={apePat} onChange={e => setApePat(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Apellido Materno</span>
                            <Input size="sm" type="text" value={apeMat} onChange={e => setApeMat(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Nombre</span>
                            <Input size="sm" type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <br />
                        <Button outline color="success" size="sm" onClick={cargarPacientes}><img src="imagenes/zoom.png" alt="buscar" /></Button>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button outline color="success" size="sm" onClick={nuevoPaciente}><img src="imagenes/add.png" alt="nuevo" /></Button>
                    </div>
                </div>
                <Tabla campos={["P", "M", "N"]}
                    cols={["Apellido Paterno", "Apellido Materno", "Nombre"]}
                    tams={[150, 150, 150, 30, 30]}
                    datos={pacientes}
                    opciones={[{ tit: 'editar', img: 'edit', fn: editar },
                    { tit: 'consultar', img: 'cotizar', fn: consultar }
                    ]}
                />
            </Container>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
        </div>
    )
}