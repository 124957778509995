import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import {Alimento} from './Alimento';

export const Alimentos = () =>{
    const edoGlobal = useContext(AppContext);
    let [alimentos,setAlimentos] = useState([]);
    let [idAlimento,setIdAlimento] = useState(0);
    let [nombre,setNombre] = useState("");
    let [tipo,setTipo] = useState("");
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    const [tipos,setTipos] = useState([]);

    useEffect(() => {
        (async () => {
          setCargando(true);
          let url = `${edoGlobal.urlws}/alimento/tipos`;
          let resp = await fetch(url);
          const tipos = await resp.json();
          setCargando(false);
          setTipos(tipos);
        })();
      }, []);
    
    async function cargarLista(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/alimento/lista/${edoGlobal.usuario.Id}?filtro=${nombre}&tipo=${tipo}`;
        let resp = await fetch(url);
        const alimentos = await resp.json();
        setCargando(false);
        setAlimentos(alimentos);
    }
    function nuevo(){
        setIdAlimento(0);
        setEditando(true);
    } 
    const editar = i =>{
        setIdAlimento(alimentos[i].I);
        setEditando(true);
    }
    const eliminar = i =>{
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarLista();
    }
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return (
      <div>
        <div style={estilo2}>
          <Alimento idAlimento={idAlimento} tipos={tipos} cerrar={cerrarFormulario} />
        </div>
        <Container style={estilo1}>
          <div className="row">
            <div className="col-4">
              <FormGroup>
                <span>Nombre</span>
                <Input
                  size="sm"
                  type="text"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroup>
                <span>Tipo</span>
                <Input type="select" size="sm" value={tipo} onChange={(e) => setTipo(e.target.value)} >
                  <option value={""}>Todos</option>
                  {tipos.map((i, k) => (
                    <option key={i.I} value={i.I}>
                      {i.N}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
            <div className="col-1">
              <br />
              <Button outline color="success" size="sm" onClick={cargarLista}>
                <img src="imagenes/zoom.png" alt="buscar" />
              </Button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Button outline color="success" size="sm" onClick={nuevo}>
                <img src="imagenes/add.png" alt="nuevo" />
              </Button>
            </div>
          </div>
          <Tabla
            campos={["N","S"]}
            cols={["Nombre","Tipo"]}
            tams={[300,70, 30, 30]}
            datos={alimentos}
            opciones={[
              { tit: "editar", img: "edit", fn: editar },
              { tit: "eliminar", img: "trash", fn: eliminar },
            ]}
          />
        </Container>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
      </div>
    );
}